import {dom} from 'core-utils';
import {scsController} from '../../stockcars-bundle';
import {scPage, OBJECT_UTILS} from '../../stockcars-utils-bundle';
import {STCK_STORE, SELECTORS, ACTIONS} from '@oneaudi/stck-store';

class FavoriteControllerClass {
	constructor() {
		this._addEventListener();
	}

	_bindContext() {
		this._favoriteClickHandler = this._favoriteClickHandler.bind(this);
		this._handleFavoriteClickInsideFavoriteView = this._handleFavoriteClickInsideFavoriteView.bind(this);
		this._handleGeneralFavoriteClick = this._handleGeneralFavoriteClick.bind(this);
		this._favoriteDeleteClickHandler = this._favoriteDeleteClickHandler.bind(this);
		this._newSearchClickHandler = this._newSearchClickHandler.bind(this);
	}

	static get defaults() {
		return {
			'classFavorite': '.js-favorite',
			'classFavoriteList': '.sc-favorite-items',
			'classDetailContainer': '.sc-detail-module.sc-detail-carinfo',
			'classFavoriteLayer': '.sc-favorite-layer',
			'classFavoriteLayerInner': '.sc-favorite-layer-inner',
			'classNewSearch': '.sc-favorite-list-empty .sc-new-search'
		};
	}

	/**
	 * returns vehicle id of current vehicle
	 *
	 * @method getCurrentVehicleId
	 * @param {HTMLElement} context - context element
	 * @return {string} returns vehilceId
	 */
	_getCurrentVehicleId(context) {
		let element = dom.getElement(FavoriteControllerClass.defaults.classDetailContainer, context);
		element = dom.isElement(element) ? element : context.closest('.js-favorite');
		return element ? element.getAttribute('data-vehicle-id') : null;
	}

	/**
	 * handleFavoriteClickInsideFavoriteView - handle favorite button click inside of favorite view
	 * @param {Event} event click event
	 * @returns {void} void
	 */
	_handleFavoriteClickInsideFavoriteView(event) {
		const layerContainer = dom.closest(event.target, FavoriteControllerClass.defaults.classFavoriteList + ' li');
		const layer = layerContainer.querySelector(FavoriteControllerClass.defaults.classFavoriteLayer);
		if (layer) {
			layer.classList.add('show');
		}
		const isPartiallyHidden = layerContainer.getBoundingClientRect().top < 0;
		if (isPartiallyHidden) {
			const innerContent = layer.querySelector(FavoriteControllerClass.defaults.classFavoriteLayerInner);
			const targetY = window.scrollY + innerContent.getBoundingClientRect().top + innerContent.getBoundingClientRect().height/2 - window.screen.height/2;
			window.scroll({top: targetY, behavior: 'smooth'});
		}
	}

	/**
	 * handleGeneraleFavoriteClick - handle favorite button click
	 * @param {boolean} isFavorite favorite state
	 * @param {string} vehicleId vehicle id
	 * @param {HTMLElement} clickedItem clicked item
	 * @returns {void} void
	 */
	_handleGeneralFavoriteClick(isFavorite, vehicleId) {
		if (isFavorite) {
			STCK_STORE.dispatch(ACTIONS.FAVORITE_VEHICLES.removeFavoriteVehicleId({vehicleId}));
		}
		else {
			STCK_STORE.dispatch(ACTIONS.FAVORITE_VEHICLES.addFavoriteVehicleId({vehicleId}));
		}
		this._changeFavoriteStatus(vehicleId);
	}

	/**
	 * favorite click handler
	 * @param {Event} event - click event
	 * @return {void}
	 */
	_favoriteClickHandler(event) {
		const clickedItem = event.target;
		const vehicleId = clickedItem.getAttribute('data-vehicle-id') || this._getCurrentVehicleId(clickedItem);
		const isFavorite = SELECTORS.VEHICLES.isFavoriteVehicle(STCK_STORE.state, vehicleId);
		const isInFavoritesList = dom.closest(clickedItem, FavoriteControllerClass.defaults.classFavoriteList);
		event.preventDefault();

		if (isInFavoritesList) {
			this._handleFavoriteClickInsideFavoriteView(event);
		}
		else {
			this._handleGeneralFavoriteClick(isFavorite, vehicleId);
		}
	}

	/**
	 * changeFavoriteStatus
	 * @param {string} vehicleId_ vehicleId
	 * @returns {void} returns nothing
	 */
	_changeFavoriteStatus(vehicleId_) {
		// clickedItem_.classList.toggle('active');
		const favButtonsInDom = dom.getElementsArray(`.js-favorite[data-vehicle-id="${vehicleId_}"]`);
		favButtonsInDom.forEach(favButton => {
			if (SELECTORS.VEHICLES.isFavoriteVehicle(STCK_STORE.state, vehicleId_)) {
				favButton.classList.add('active');
			}
			else {
				favButton.classList.remove('active');
			}
		});
	}


	/**
	 * favoriteDeleteClickHandler - handles click on delete layers delete button
	 * @param {Event} event click event
	 * @returns {void} void
	 */
	_favoriteDeleteClickHandler(event) {
		const vehicleId = event.target.dataset.vehicleId;
		const layer = dom.closest(event.target, FavoriteControllerClass.defaults.classFavoriteList).querySelector(FavoriteControllerClass.defaults.classFavoriteLayer);

		if (layer) {
			layer.classList.remove('show');
		}
		STCK_STORE.dispatch(ACTIONS.FAVORITE_VEHICLES.removeFavoriteVehicleId({vehicleId}));
	}


	/**
	 * favoriteCloseLayerClickHandler - handles click on delete layers close button
	 * @param {Event} event click event
	 * @returns {void} void
	 */
	_favoriteCloseLayerClickHandler(event) {
		const layer = dom.closest(event.target, FavoriteControllerClass.defaults.classFavoriteList + ' li').querySelector(FavoriteControllerClass.defaults.classFavoriteLayer);
		if (layer) {
			layer.classList.remove('show');
		}
	}

	/**
	 * newSearchClickHandler
	 * @param {event} event clickEvent
	 * @return {void} returns nothing
	 */
	async _newSearchClickHandler(event) {
		event.preventDefault();
		await scsController.clearAllFilters();
		const href = event.target.getAttribute('href');
		scPage.simulateNmPageOpen(href);
	}

	/**
	 * loadFavoriteVehiclesFromScs
	 * @public
	 * @param {array<string>} vehicleIds vehicle ids to load from scs
	 * @returns {Promise<void>} void
	 */
	async loadFavoriteVehiclesFromScs(vehicleIds = []) {
		if (vehicleIds.length) {
			const sorting = OBJECT_UTILS.getNestedObject(STCK_STORE.state, 'sorting.favorites'.split("."));
			const vehicleResponse = await scsController.fetchVehiclesByIds(vehicleIds, sorting).catch(error => {
				console.log(error);
				throw error;
			});
			const vehicleBasic = vehicleResponse && vehicleResponse.vehicleBasic ? vehicleResponse.vehicleBasic : [];
			const recievedVehicleIds = vehicleBasic.map((vehicle) => vehicle.id);
			STCK_STORE.dispatch(ACTIONS.VEHICLES.setVehicles({vehicleIds: vehicleBasic}));
			STCK_STORE.dispatch(ACTIONS.FAVORITE_VEHICLES.setFavoriteVehicleIds({vehicleIds: recievedVehicleIds}));
		}
		else {
			STCK_STORE.dispatch(ACTIONS.FAVORITE_VEHICLES.setFavoriteVehicleIds({vehicleIds: []}));
		}
	}

	async getSortedFavoriteVehiclesFromScs(vehicleIds = [], sortingAttribute = SELECTORS.SORTING.getFavSorting(STCK_STORE.state)) {
		const vehicleResponse = await scsController.fetchVehiclesByIds(vehicleIds, sortingAttribute).catch(error => {
			console.log(error);
			throw error;
		});
		const vehicleBasic = vehicleResponse && vehicleResponse.vehicleBasic ? vehicleResponse.vehicleBasic : [];
		return vehicleBasic.map((vehicle) => vehicle.id);
	}


	async checkCurrentFavoritesUpToDate() {
		const previousFavoriteIds = SELECTORS.VEHICLES.getFavoriteVehicleIdsState(STCK_STORE.state) || [];
		let vehicleIds = [];
		try {
			if (previousFavoriteIds.length) {
				const vehicleResponse = await scsController.fetchVehiclesByIds(previousFavoriteIds);
				const vehicleBasics = vehicleResponse && vehicleResponse.vehicleBasic ? vehicleResponse.vehicleBasic : [];
				vehicleIds = (vehicleBasics).map((vehicle) => vehicle.id);
				STCK_STORE.dispatch(ACTIONS.VEHICLES.setVehicles({vehicles: vehicleBasics}));
				STCK_STORE.dispatch(ACTIONS.FAVORITE_VEHICLES.setFavoriteVehicleIds({vehicleIds}));
			}
		}
		catch (error) {
			console.error("can't check last visited cars up to date: ", error);
			STCK_STORE.dispatch(ACTIONS.FAVORITE_VEHICLES.deleteFavoriteVehicleIds());
		}
		finally {
			let count = (previousFavoriteIds.length - vehicleIds.length);
			count = count < 0 ? 0 : count;
			STCK_STORE.dispatch(ACTIONS.UI.setOutdatedCount({count}));
			console.log(`restored ${previousFavoriteIds.length} of ${vehicleIds.length} previous favorite cars`);
		}
	}

	/**
	 * add all Event Listener
	 *
	 * @method addEventListener
	 * @return {void} returns nothing
	 */
	_addEventListener() {
		this._bindContext();
		const domDelegate = dom.getEventDelegate('body');
		domDelegate.on('click', '.sc-favorite-delete', this._favoriteDeleteClickHandler);
		domDelegate.on('click', '.sc-favorite-abort', this._favoriteCloseLayerClickHandler);
		domDelegate.on('click', FavoriteControllerClass.defaults.classFavorite, this._favoriteClickHandler);
		domDelegate.on('click', FavoriteControllerClass.defaults.classNewSearch, this._newSearchClickHandler);
	}
}

const favoriteController = new FavoriteControllerClass();

export {favoriteController, FavoriteControllerClass};
