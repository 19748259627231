import {appEvents} from 'core-application';
import {dom} from 'core-utils';
import {template} from 'core-utils';
import {ScViewTilesTemplateLookup} from '../../stockcars-bundle';
import {favoriteController} from './favorite-controller';
import {STCK_STORE, SELECTORS, ACTIONS} from '@oneaudi/stck-store';
import {FORMAT_UTILS, lazyLoad, OBJECT_UTILS} from '../../stockcars-utils-bundle';


export default class FavoriteElement extends HTMLElement {
	constructor() {
		super();
		this._scMarket = null;
		this._scDetailLinkPattern = null;
		this._scDefaultUrl = null;
		this.unsubscribeFavoriteVehiclesObserver = null;
		this.unsubscribeCompareVehiclesObserver = null;
	}

	/**
	 * connectedCallback
	 * @returns {void} nothing
	 */
	connectedCallback() {
		this._addEventListener();
		// @TODO why loading favorites again???
		favoriteController.loadFavoriteVehiclesFromScs(SELECTORS.VEHICLES.getFavoriteVehicleIdsState(STCK_STORE.state));
	}

	/**
	 * disconnectedCallback
	 * @returns {void} nothing
	 */
	disconnectedCallback() {
		this._removeEventListener();
	}

	/**
	 * default Values used several times
	 * @member {Object} oDefaults
	 * @example
	 * //To call inner values:
	 * FavoriteElement.defaults = { 'className' : '.my-name' };
	 */
	static get defaults() {
		return {
			classFavoriteLinks: '.js-favorite',
			classDetailContainer: '.sc-detail-module.sc-detail-carinfo',
			classFavorite: '.js-favorite',
			classResultItems: '.sc-result-items',
			classView: '.sc-favorites-view',
			classFavoriteItems: '.sc-favorite-items',
			classFavoriteCount: '.sc-favorite-count span',
			sortDropdown: '#possibleFavSortAttributes',
			classInfoPanel: '.sc-favorite-info-panel',
			phevIdentifier: 'OVC_HEV'
		};
	}

	/**
	 * update - publice update function - callback for model trigger update
	 * @returns {void} void
	 */
	update() {
		this._renderFavorites();
	}

	/**
	 * _renderFavorites - render favorite view
	 * @private
	 * @returns {void} void
	 */
	_renderFavorites() { // eslint-disable-line max-statements
		this._setProperties();
		const favoriteVehiclesMap = SELECTORS.VEHICLES.getFavoriteVehiclesMap(STCK_STORE.state);
		const selectUsedCar = document.querySelector('.usedCar');
		const selectNewCar = document.querySelector('.newCar');
		try {
			const jsonString = JSON.stringify([...favoriteVehiclesMap.values()]);
			const clonedFavoriteVehiclesMap = JSON.parse(jsonString);
			// using cloned vehicles from here on =>
			const favoriteVehicles = this._getPreparedVehicleItems(clonedFavoriteVehiclesMap);
			const isNewCar = favoriteVehicles.find(selectCar => selectCar.type === 'N');
			if (isNewCar) {
				selectUsedCar.classList.add('sc-hidden');
				selectNewCar.classList.remove('sc-hidden');
			}
			else {
				selectUsedCar.classList.remove('sc-hidden');
				selectNewCar.classList.add('sc-hidden');
			}
			const itemsString = this._getItemString(favoriteVehicles);
			this._updateViewCompareState(SELECTORS.VEHICLES.getCompareVehicleIdsState(STCK_STORE.state).length);
			this._renderFavoriteElement(itemsString);
			this._emitContentRenderedEvent();
			this._setHeadlineCount();
			this._setDefaultUrl();
			const favSorting = STCK_STORE.state.sorting.favorites;
			this._setSelectedSortingAttribute(favSorting);
			this._showOutDatedDialogue(SELECTORS.UI.getOutdatedCount(STCK_STORE.state));
			lazyLoad.register(this);
		}
		catch (err) {
			console.warn(err);
		}
	}

	/**
	 * _setProperties - set private properties belonging to dom
	 * @private
	 * @returns {void} void
	 */
	_setProperties() {
		this._favoriteContainer = dom.getElement('ul', this);
		this._scDetailLinkPattern = dom.getElement(FavoriteElement.defaults.classFavoriteItems, this).getAttribute('data-details-url') || '';
		this._scDefaultUrl = dom.getElement(FavoriteElement.defaults.classFavoriteItems, this).getAttribute('data-default-url') || '';
		this._scMarket = dom.getElement(FavoriteElement.defaults.classFavoriteItems, this).getAttribute('data-market') || '';
	}

	/**
	 * _renderFavoriteElement - adds generated html string into dom
	 * @param {string} itemsString generated items html string
	 * @private
	 * @returns {void} void
	 */
	_renderFavoriteElement(itemsString = '') {
		this._favoriteContainer.innerHTML = '';
		this._favoriteContainer.innerHTML = itemsString;
	}

	/**
	 * _emitContentRenderedEvent - emit content rendered event - used for footnotes
	 * @private
	 * @returns {void} void
	 */
	_emitContentRenderedEvent() {
		const contentRendered = new CustomEvent(appEvents.CONTENT_RENDERED, {
			element: this._favoriteContainer,
			type: 'module',
			detail: {element: this._favoriteContainer}
		});
		document.dispatchEvent(contentRendered);
	}

	/**
	 * _getPreparedVehicleItems
	 * @param {array<object>} vehicleItems raw vehicle objects
	 * @returns {array<object>} array of vehicle objects
	 * @private
	 */
	_getPreparedVehicleItems(vehicleItems = []) {

		this._addVehiclesFavoriteAndCompareState(vehicleItems, SELECTORS.VEHICLES.getCompareVehicleIdsState(STCK_STORE.state));
		this._addVehiclesDetailLinks(vehicleItems);
		this._addDealerSpecialLink(vehicleItems);
		this._addFuelTypes(vehicleItems);
		this._checkElectrical(vehicleItems);
		this._addTileTabsJson(vehicleItems);
		this._prepareDataForScope(vehicleItems); // Scope STCK_FINANCE, will be removed once the rate is shown on all markets

		return vehicleItems;
	}

	/**
	 * _addVehiclesFavoriteAndCompareState - adds favorite state true to all vehicles and compare for all active compare items
	 * @param {array<object>} vehicleItems vehicle objects
	 * @param {array} compareIdsArray compared vehicle Ids
	 * @private
	 * @returns {void} void
	 */
	_addVehiclesFavoriteAndCompareState(vehicleItems = [], compareIdsArray = []) {
		vehicleItems.forEach(function (vehicle) {
			vehicle.compare = compareIdsArray.indexOf(vehicle.id) >= 0;
			vehicle.favorite = true;
		});
	}

	/**
	 * _addVehiclesDetailLinks - add detail url to each favorite vehicle item
	 * @param {array<object>} vehicleItems vehicle objects
	 * @private
	 * @returns {void} void
	 */
	_addVehiclesDetailLinks(vehicleItems = []) {
		vehicleItems.forEach(vehicle => {
			this._addDetailLinks(vehicle);
		});
	}

	/**
	 * _getItemString - generates item html string from tiles template
	 * @param {array<object>} favoriteVehicles vehicle objects
	 * @private
	 * @returns {string} rendered items html string
	 */
	_getItemString(favoriteVehicles = []) {
		let itemsString = '';
		if (favoriteVehicles.length <= 0) {
			itemsString = template.render(ScViewTilesTemplateLookup.getTemplate('favorites-empty'));
		}
		else {
			itemsString = template.render(ScViewTilesTemplateLookup.getTemplate(this._scMarket), {
				data: favoriteVehicles
			});
		}
		return itemsString;
	}

	/**
	 * Update dom when compare EVENTS.SC_COMPARE_UPDATE is fired
	 * @param {Number} compareItemsCount - number of compare Ids
	 * @returns {void} - void
	 */
	_updateViewCompareState(compareItemsCount = 0) {

		if (compareItemsCount === 3) {
			this.classList.add('sc-cmp-max');
		}
		else {
			this.classList.remove('sc-cmp-max');
		}
	}

	/**
	 * handle dropDown selection for sort
	 * @method _handleSortDropDownChanges
	 * @param {Event} event_ - dropDown change event
	 * @returns {void}
	 */
	async _handleSortDropDownChanges(event_) {
		const selectedValue = event_.target.options[event_.target.selectedIndex].value;
		this._setSelectedSortingAttribute(selectedValue);
		const favorites = SELECTORS.VEHICLES.getFavoriteVehicleIdsState(STCK_STORE.state);
		STCK_STORE.dispatch(ACTIONS.SORTING.setSorting({sorting: {favorites: selectedValue}}));
		if (favorites.length) {
			const sortedIds = await favoriteController.getSortedFavoriteVehiclesFromScs(SELECTORS.VEHICLES.getFavoriteVehicleIdsState(STCK_STORE.state), selectedValue);
			STCK_STORE.dispatch(ACTIONS.FAVORITE_VEHICLES.setFavoriteVehicleIds({vehicleIds: sortedIds}));
		}
	}

	/**
	 * set selected dropdown option
	 * @param {string} value_ - selected key from dropdown
	 * @returns {void}
	 */
	_setSelectedSortingAttribute(value_) {
		const optionsArr = dom.getElementsArray(FavoriteElement.defaults.sortDropdown + ' option', this);
		optionsArr.forEach(element => {
			element.removeAttribute('selected');
			if (element.value === value_) {
				element.setAttribute('selected', 'selected');
			}
		});
	}

	/**
	 * _setHeadlineCount - sets favorite views headline count
	 * @private
	 * @returns {void} void
	 */
	_setHeadlineCount() {
		const headlineCountElement = dom.getElement(FavoriteElement.defaults.classFavoriteCount, this);
		headlineCountElement.innerHTML = FORMAT_UTILS.formatCount(SELECTORS.VEHICLES.getFavoriteVehicleIdsState(STCK_STORE.state).length);
	}


	/**
	 * _addDetailLinks
	 * @method _addDetailLinks
	 * @param {object} vehicle vehicle object
	 * @private
	 * @returns {void} void
	 */
	_addDetailLinks(vehicle) {
		vehicle.url = (this._scDetailLinkPattern || '').replace(/SC_VEHICLE_ID/i, vehicle.id);
		if (vehicle.url.indexOf('sc_detail') > -1 && vehicle.url.indexOf('sc_detail_fav') < 0) {
			vehicle.url = vehicle.url.replace(/sc_detail/, 'sc_detail_fav');
		}
	}

	/**
	 * _showOutDatedDialogue - show outdated dialogue when count is greater then 0
	 * @method _showOutDatedDialogue
	 * @param {number} outDatedCount count of removed/outdated vehicles
	 * @private
	 * @returns {void} void
	 */
	_showOutDatedDialogue(outDatedCount = 0) {
		if (outDatedCount > 0) {
			const infoPanel = dom.getElement(FavoriteElement.defaults.classInfoPanel, this);
			const infoPanelParag = dom.getElement(FavoriteElement.defaults.classInfoPanel + ' p span', this);

			infoPanel.classList.add('show');
			infoPanelParag.innerHTML = outDatedCount;
			STCK_STORE.dispatch((ACTIONS.UI.setOutdatedCount({count: 0})));
		}
	}

	/**
	 * _addDealerSpecialLink - add dealer special link to each vehicle object
	 * @method _addDealerSpecialLink
	 * @param {array<object>} vehicles vehicle objects
	 * @private
	 * @returns {void} void
	 */
	_addDealerSpecialLink(vehicles = []) {
		const specialLink = (this.getAttribute('data-dealer-speciallink') || '').trim();

		vehicles.forEach((vehicleObj) => {
			vehicleObj.dealer.cqSpecialLink = specialLink.replace(/(href=\"[^\"]*)/, '$1?vehicleid=' + vehicleObj.id); // eslint-disable-line no-useless-escape
		});
	}

	/**
	 * addFuelTypes
	 * @method addFuelTypes
	 * @param {Array} vehicles_ vehicle array for preparing with fuel types (also hybrids)
	 * @returns {Array} prepared array
	 */
	_addFuelTypes(vehicles_) {
		vehicles_.forEach((vehicle) => {
			let fuels = [];
			if (vehicle.io && (vehicle.io.hasNedc || vehicle.io.hasWltp) && vehicle.io.fuels.length) {
				vehicle.io.fuels.forEach(item => {
					if (!!item.fuel) {
						let type = window.i18n[`sc.tiles.fuel.type.${item.fuel.toLowerCase()}.label`] || '';
						fuels.push(type);
					}
				});
			}
			vehicle.fuelTypes = fuels.join(' / ');
		});
	}

	/**
	 * checkElectrical
	 * @method checkElectrical
	 * @param {Array} vehicles_ vehicle array for checking if its elements are electrical
	 * @returns {Array} prepared array
	 */
	_checkElectrical(vehicles_) {
		vehicles_.forEach((vehicle) => {
			let isElectrical = false;
			const engineType = OBJECT_UTILS.getNestedObject(vehicle, 'io.engineType'.split('.')) || '';
			if (vehicle.io && vehicle.io.fuels && vehicle.io.fuels.length) {
				isElectrical = !!(vehicle.io.fuels.filter((item = {}) => {
					return (item.fuel === "ELECTRICAL") && (engineType !== FavoriteElement.defaults.phevIdentifier);
				}).length);
			}
			vehicle.isElectrical = isElectrical;
		});
		return vehicles_;
	}

	/**
	 * private function handling the scope STCK_FINANCE
	 * if the scope is not set, remove the finance data
	 * @todo remove once the rate is shown on all markets
	 * @param {Object} vehicleDataItems_ -  the vehicle data
	 * @returns {Object} the changed vehicle data
	 */
	_prepareDataForScope(vehicleDataItems_ = []) {
		if (!(SETUPS.get('stockcar.financing.rate.show') && !SETUPS.get('scopes.hide.finance.in.tiles'))) {
			vehicleDataItems_.forEach((item) => {
				item.financing = {};
			});
		}
		else if (SETUPS.get('scopes.hide.finance.for.ecom')) {
			vehicleDataItems_.forEach((item) => {
				if (item.buyableOnline) {
					item.financing = {};
				}
			});
		}
	}

	/**
	 *
	 * @method setDefaultUrl
	 * @returns {void} void
	 */
	_setDefaultUrl() {
		if (SELECTORS.VEHICLES.getMatchingVehicleIdsState(STCK_STORE.state).length <= 0) {
			const newSearchButton = dom.getElement('.sc-new-search', this);
			newSearchButton.href = !!this._scDefaultUrl ? this._scDefaultUrl : '#';
		}
	}

	/**
	 * add results tab json
	 * @param {object} resultsObject - vehicle results object
	 * @return {object} resultsObject - modified vehicle results object
	 * @private
	 */
	_addTileTabsJson(resultsObject) {
		let stringifiedJSON = dom.getElement(FavoriteElement.defaults.classFavoriteItems, this).getAttribute('data-tile-tabs') || '';

		resultsObject.forEach(vehicle => {
			vehicle.tileTabs = stringifiedJSON;
		});
		return resultsObject;
	}

	/**
	 * add all Event Listener
	 * @method addEventListener
	 * @returns {void} void
	 */
	_addEventListener() {
		this.update = this.update.bind(this);
		this.unsubscribeCompareVehiclesObserver = STCK_STORE.observeStateFromStore(SELECTORS.VEHICLES.getCompareVehicleIdsState, this.update);
		this.unsubscribeFavoriteVehiclesObserver = STCK_STORE.observeStateFromStore(SELECTORS.VEHICLES.getFavoriteVehicleIdsState, this.update);
		this._handleSortDropDownChanges = this._handleSortDropDownChanges.bind(this);
		dom.getEventDelegate('body').on('change', FavoriteElement.defaults.sortDropdown, this._handleSortDropDownChanges);
	}

	/**
	 * remove all Event Listener
	 * @method addEventListener
	 * @returns {void} void
	 */
	_removeEventListener() {
		this.unsubscribeFavoriteVehiclesObserver();
		this.unsubscribeCompareVehiclesObserver();
		dom.getEventDelegate('body').off('change', FavoriteElement.defaults.sortDropdown, this._handleSortDropDownChanges);
	}
}

if (window.customElements.get('sc-favorite-element') === undefined) {
	window.customElements.define('sc-favorite-element', FavoriteElement);
}

